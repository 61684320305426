<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>快速排课</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div>
                <div style="height: 20px;"></div>
                <!--详情-->
                <el-form v-loading="loading" size="medium" :label-width="this.env.label_width">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="选择门店">
                                <el-select v-model="store_uuid"
                                           filterable
                                           placeholder="请选择门店"
                                           style="width: 100%"
                                           @change="selectStore">
                                    <el-option
                                            v-for="item in store_optionals"
                                            :key="item.store_uuid"
                                            :label="item.store_name"
                                            :value="item.store_uuid">
                                        <span style="float: left">{{ item.store_name }}
                                            <!--{{item.city}}{{item.county}}{{item.address}}-->
                                        </span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.company_name }}】
                                        </span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="选择教室">
                                <el-select v-model="classroom_uuid"
                                           filterable
                                           placeholder="请选择教室"
                                           style="width: 100%"
                                           @change="selectClassroom">
                                    <el-option
                                            v-for="item in classroom_optionals"
                                            :key="item.classroom_uuid"
                                            :label="item.classroom_name"
                                            :value="item.classroom_uuid">
                                        <span style="float: left">{{ item.classroom_name }}
                                        </span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">
                                            【{{ item.area }}平/可容纳{{ item.capacity }}人】
                                        </span>
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="选择日期">
                                <el-date-picker
                                        :disabled="this.Tool.is_empty(classroom_uuid)"
                                        v-model="selectWeek"
                                        type="week"
                                        format="yyyy 第 WW 周"
                                        value-format="yyyy-MM-dd"
                                        :picker-options="pickerOptions"
                                        @change="selectDate"
                                        placeholder="选择周">
                                </el-date-picker>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <el-button
                                        v-if="is_auth('lessonschedule.group.issave') && !this.Tool.is_empty(weekArrangeData) && !this.Tool.is_empty(selectWeek)"
                                        icon="el-icon-plus"
                                        size="medium" type="primary" @click="()=>{
                                            this.arrangePage = true
                                            this.createdTimeArrange()
                                        }">排课
                                </el-button>
                                <el-button
                                        v-if="is_auth('lessonschedule.group.issave') && !this.Tool.is_empty(weekArrangeData) && !this.Tool.is_empty(selectWeek)"
                                        @click="selectDate(selectWeek)"  icon="el-icon-refresh">刷新</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!--课程表-->
                    <el-row v-if="!this.Tool.is_empty(weekArrangeData) && !this.Tool.is_empty(selectWeek)"
                            v-loading="loading">
                        <!--时间表-->
                        <el-col :span="24">
                            <div class="time-arrange timetable">
                                <div style="width: 14%" class="time-arrange-list"
                                     v-for="(item, index) in weekArrangeData" :key="index">
                                    <div style="text-align: center;">
                                        <div>周{{ item.week }}</div>
                                        <div class="text-se">（{{ item.date }}）</div>
                                    </div>
                                    <div>
                                        <!--时间占用条-->
                                        <div>
                                            <div class="text-se">00:00</div>
                                            <div class="time-arrange-column">
                                                <!--时间刻度提醒-->
                                                <div class="time-tip">
                                                    <div class="time-h" v-for="item_time in time24_tip"
                                                         :key="item_time">
                                                        <div class="time-h-border" v-if="item_time !=='00:00'">
                                                            {{ item_time }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--门店教室时间占用卡片-->
                                                <div class="time-arrange-column-store-classroom">
                                                    <div v-if="classroomOccupy(item.date,1)">
                                                        <el-tooltip placement="top-start"
                                                                    v-for="(item_classroomoccupy, index_classroomoccupy) in classroomOccupy(item.date)"
                                                                    :key="index_classroomoccupy"
                                                                    :content="item_classroomoccupy.start_end_time_hi"
                                                        >
                                                            <div class="time-arrange-column-store-classroom-card"
                                                                 :style="occupyTipStyle(item_classroomoccupy)">
                                                                <div style="margin: 5px;">
                                                                    <span>
                                                                        <span v-if="item_classroomoccupy.lesson_type===1">[团课]</span>
                                                                        <span v-if="item_classroomoccupy.lesson_type===2">[训练营]</span>
                                                                        <span>{{
                                                                                item_classroomoccupy.start_end_time_hi
                                                                            }}</span><br>
                                                                        <el-button
                                                                                type="text"
                                                                                class="time-arrange-column-store-classroom-card-button"
                                                                                @click="to_details(item_classroomoccupy.lesson_type,item_classroomoccupy.good_uuid)"
                                                                        >{{ item_classroomoccupy.title }}</el-button>
                                                                    </span><br>
                                                                    <span>
                                                                        {{ item_classroomoccupy.user_nick }}
                                                                        ({{
                                                                            item_classroomoccupy.bookings_sum
                                                                        }}/{{ item_classroomoccupy.quota }})
                                                                    </span><br>
                                                                </div>
                                                            </div>
                                                        </el-tooltip>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="text-se">24:00</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-form>


            </div>
        </div>

        <!--排课-->
        <el-dialog
                v-if="is_auth('lessonschedule.group.issave')"
                title="排课"
                :visible.sync="arrangePage"
                width="90%"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
        >
            <el-row v-loading="loading">
                <!--时间表-->
                <el-col :span="24">
                    <el-form>
                        <el-form-item label="选择日期">
                            <el-date-picker
                                    :disabled="this.Tool.is_empty(classroom_uuid)"
                                    v-model="selectWeek"
                                    type="week"
                                    format="yyyy 第 WW 周"
                                    value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions"
                                    @change="selectDate"
                                    placeholder="选择周">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-checkbox-group v-model="arrangeQuickTool">
                            <el-checkbox label="sync_select_coach">同步选择教练</el-checkbox>
                            <el-checkbox label="sync_select_lesson">同步选择课程</el-checkbox>
                            <el-checkbox label="sync_select_start_time">同步选择开始时间</el-checkbox>
                            <el-checkbox label="sync_select_publish_time">同步选择上架时间</el-checkbox>
                        </el-checkbox-group>
                        <span class="tip-error">(勾选后设置周一的对应信息，将会把对应信息同步至其他日期)</span>
                    </div>
                    <div style="height: 20px;"></div>
                    <div class="time-arrange">
                        <div class="time-arrange-list" v-for="(item, index) in weekArrangeData" :key="index">
                            <div style="text-align: center;">
                                <div>周{{ item.week }}</div>
                                <div class="text-se">（{{ item.date }}）</div>
                            </div>
                            <div style="display: flex;">
                                <!--时间占用条-->
                                <div>
                                    <div class="text-se">00:00</div>
                                    <div class="time-arrange-column">
                                        <!--门店教室时间占用-->
                                        <div class="time-arrange-column-type">
                                            <div v-if="classroomOccupy(item.date,1)">
                                                <el-tooltip placement="top-start"
                                                            v-for="(item_classroomoccupy, index_classroomoccupy) in classroomOccupy(item.date)"
                                                            :key="index_classroomoccupy"
                                                            :content="item_classroomoccupy.start_end_time_hi"
                                                >
                                                    <div class="time-arrange-column-store-classroom-card"
                                                         :style="occupyTipStyle(item_classroomoccupy)"></div>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                        <!--教练时间占用-->
                                        <!--                                        <div class="time-arrange-column-type">-->
                                        <!--                                            <div class="time-arrange-column-coach"></div>-->
                                        <!--                                        </div>-->
                                    </div>
                                    <div class="text-se">24:00</div>
                                </div>

                                <!--表单内容-->
                                <div class="time-arrange-form">
                                    <el-form label-position="top" size="mini">
                                        <el-form-item label="选择教练">
                                            <el-select v-model="item.coach_uuid"
                                                       filterable
                                                       @change="selectCoach(item.coach_uuid,index)"
                                                       placeholder="请选择教练"
                                                       style="width: 100%">
                                                <el-option
                                                        v-for="itemcoach in coach_optionals"
                                                        :key="itemcoach.coach_uuid"
                                                        :label="`${itemcoach.user_nick}(${itemcoach.user_name})`"
                                                        :value="itemcoach.coach_uuid">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="选择课程">
                                            <el-select v-model="item.lesson_uuid"
                                                       filterable
                                                       @change="selectLesson(item.lesson_uuid,index)"
                                                       placeholder="请选择课程"
                                                       style="width: 100%">
                                                <el-option
                                                        v-for="itemlesson in lesson_optionals"
                                                        :key="itemlesson.lesson_uuid"
                                                        :label="itemlesson.lesson_name"
                                                        :value="itemlesson.lesson_uuid">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                        <el-form-item label="名称">
                                            <el-input v-model="item.title" maxlength="200" show-word-limit></el-input>
                                        </el-form-item>
                                        <el-form-item label="门店">
                                            <span>{{ select_store_data.store_name }}</span>
                                        </el-form-item>
                                        <el-form-item label="教室">
                                            <span>{{ select_classroom_data.classroom_name }}</span>
                                        </el-form-item>
                                        <el-form-item label="容纳人数">
                                            <span>{{
                                                    select_classroom_data.capacity
                                                }}人/{{ select_classroom_data.area }}平</span>
                                        </el-form-item>
                                        <el-form-item label="课程人数">
                                            <span>{{ item.lesson_quota }}人</span>
                                        </el-form-item>
                                        <el-form-item label="人数上限">
                                            <el-input-number v-model="item.quota" :step="1" :min="0" :max="9999"
                                                             step-strictly
                                                             style="width: 100%"></el-input-number>
                                        </el-form-item>
                                        <el-form-item label="最少开课人数">
                                            <el-input-number v-model="item.bookings_min" :step="1" :min="0"
                                                             :max="item.quota"
                                                             step-strictly
                                                             style="width: 100%"></el-input-number>
                                        </el-form-item>
                                        <el-form-item label="提前确定是否开课（小时）">
                                            <el-input-number v-model="item.determine_time" :step="1" :min="0"
                                                             :max="999"
                                                             step-strictly
                                                             style="width: 100%"></el-input-number>
                                        </el-form-item>
<!--                                        <el-form-item label="参考价格">-->
<!--                                            <span>￥{{ item.price_indication }}</span>-->
<!--                                        </el-form-item>-->
<!--                                        <el-form-item label="门店价格">-->
<!--                                            <span>￥{{ item.price_store }}</span>-->
<!--                                        </el-form-item>-->
                                        <el-form-item label="原价（0不设置）">
                                            <el-input-number v-model="item.originally_price" :step="0.01" :min="0"
                                                             :max="999999"
                                                             step-strictly
                                                             style="width: 100%"></el-input-number>
                                        </el-form-item>
                                        <el-form-item label="预约价格">
                                            <el-input-number v-model="item.price" :step="0.01" :min="0" :max="999999"
                                                             step-strictly
                                                             style="width: 100%"></el-input-number>
                                        </el-form-item>
                                        <el-form-item label="开始时间">
                                            <el-time-picker
                                                    v-model="item.start_time"
                                                    :picker-options="{ selectableRange: '00:00:00 - 23:59:00' }"
                                                    value-format="HH:mm"
                                                    format="HH:mm"
                                                    placeholder="选择开始时间"
                                                    @change="selectStartTime(item.start_time,item.lesson_duration,index)"
                                                    style="width: 100%"
                                            >
                                            </el-time-picker>
                                        </el-form-item>
                                        <el-form-item label="课程时间">
                                            <span>{{ item.lesson_duration }}分钟</span>
                                        </el-form-item>
                                        <el-form-item label="结束时间">
                                            <span>{{ item.end_time }}</span>
                                        </el-form-item>
                                        <el-form-item label="排队等候">
                                            <el-switch
                                                    v-model="item.is_queue_up"
                                                    :active-value="1"
                                                    :inactive-value="0"
                                            ></el-switch>
                                        </el-form-item>
                                        <el-form-item label="允许退款">
                                            <el-switch
                                                    v-model="item.is_refund"
                                                    :active-value="1"
                                                    :inactive-value="0"
                                            ></el-switch>
                                        </el-form-item>
                                        <el-form-item label="上架时间(选填)">
                                            <el-date-picker
                                                    v-model="item.publish_time"
                                                    type="datetime"
                                                    placeholder="选择上架时间"
                                                    format="yyyy-MM-dd HH:mm"
                                                    value-format="yyyy-MM-dd HH:mm"
                                                    :picker-options="publishTimePickerOptions"
                                                    @change="selectPublishTime(item.publish_time,index)"
                                                    style="width: 100%">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item label="提示(选填)">
                                            <el-input v-model="item.tip"
                                                      type="textarea"
                                                      :rows="3"
                                                      maxlength="200"
                                                      placeholder="提示内容"
                                                      show-word-limit></el-input>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-row>
                                                <el-col :span="12">
                                                    <el-button v-if="is_auth('lessonschedule.group.issave')"
                                                               @click="save(index)"
                                                               size="mini" type="primary">保存
                                                    </el-button>
                                                </el-col>
                                                <el-col :span="12">
                                                    <el-button v-if="is_auth('lessonschedule.group.issave')"
                                                               @click="TimeArrange(index)"
                                                               size="mini">清空
                                                    </el-button>
                                                </el-col>
                                            </el-row>
                                        </el-form-item>
                                    </el-form>
                                </div>

                            </div>
                        </div>
                    </div>
                </el-col>

                <el-col :span="16">
                    <div style="height: 20px;"></div>
                    <el-button v-if="is_auth('lessonschedule.group.issave')"
                               size="medium" type="primary" @click="save(null)">全部保存
                    </el-button>
                    <el-button v-if="is_auth('lessonschedule.group.issave')"
                               size="medium" @click="createdTimeArrange">全部清空
                    </el-button>
                </el-col>
            </el-row>
        </el-dialog>


    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '快速排课',
            time24_tip: this.get_time24_tip(),
            loading: false,                 // 加载状态
            arrangePage: false,             // 排课显示隐藏
            selectWeek: '',                 // 选择的周
            weekArrangeData: [],            // 一周安排数据

            store_optionals: [],            // 可选门店信息
            classroom_optionals: [],        // 可选教室信息
            coach_optionals: [],            // 可选教练信息
            lesson_optionals: [],           // 可选课程信息

            classroom_occupy: [],            // 教室占用数据

            store_uuid: '',                 // 选择的门店id
            select_store_data: null,        // 选中的门店数据
            classroom_uuid: '',             // 选择的教室id
            select_classroom_data: null,    // 选中的教室数据

            // 快速排课工具，默认勾选
            arrangeQuickTool: [
                // 'sync_select_coach',
                // 'sync_select_lesson',
                // 'sync_select_start_time',
                // 'sync_select_publish_time',
            ],

            // 日期选择 配置
            pickerOptions: {
                firstDayOfWeek: 1,      // 从周一开始
            },
            // 上架时间选择 配置
            publishTimePickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 生成时间线提醒
        get_time24_tip() {
            let time = []
            for (let i = 0; i < 24; i++) {
                let time_tip = i < 10 ? '0' + i + ':00' : i + ':00'
                time.push(time_tip)
            }
            return time
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.getStore()
        },
        // 保存
        save(index = null) {
            let weekArrangeData = []

            if (index === null) {
                weekArrangeData = this.weekArrangeData      // 一周排课数据
            } else {
                weekArrangeData.push(this.weekArrangeData[index])      // 一天排课数据
            }

            let arrange_data = [];
            let warning_tip = [];
            let is_save = true
            for (let val of weekArrangeData) {
                // 判断是否有 教练信息和课程信息
                if (this.Tool.is_empty(val.coach_uuid) || this.Tool.is_empty(val.lesson_uuid)) {
                    warning_tip.push({
                        date: val.date,
                        week: val.week,
                    })
                    continue
                }
                /**验证必填数据**/
                // 判断是否有标题
                if (this.Tool.is_empty(val.title)) {
                    is_save = false
                    let message = `周${val.week}（${val.date}） 未输入名称`
                    this.$message({message, type: 'warning', duration: this.env.message_error,});
                    break
                }
                // 判断是否人数
                if (this.Tool.is_empty(val.quota) || val.quota === 0) {
                    is_save = false
                    let message = `周${val.week}（${val.date}） 未输入人数上限`
                    this.$message({message, type: 'warning', duration: this.env.message_error,});
                    break
                }
                // 判断是否有价格
                if (this.Tool.is_empty(val.price) || val.price === 0) {
                    is_save = false
                    let message = `周${val.week}（${val.date}） 未输入预约价格`
                    this.$message({message, type: 'warning', duration: this.env.message_error,});
                    break
                }
                // 判断是否选择开始时间
                if (this.Tool.is_empty(val.start_time)) {
                    is_save = false
                    let message = `周${val.week}（${val.date}） 未选择开始时间`
                    this.$message({message, type: 'warning', duration: this.env.message_error,});
                    break
                }
                // 判断时间是否冲突


                // 生成提交数据
                arrange_data.push({
                    store_uuid: this.store_uuid,
                    classroom_uuid: this.classroom_uuid,
                    date_ymd: val.date,
                    coach_uuid: val.coach_uuid,
                    store_lesson_uuid: val.store_lesson_uuid,
                    lesson_uuid: val.lesson_uuid,
                    title: val.title,
                    bookings_min: val.bookings_min,
                    determine_time: val.determine_time,
                    originally_price: val.originally_price,
                    quota: val.quota,
                    price: val.price,
                    start_time: val.start_time,
                    end_time: val.end_time,
                    is_queue_up: val.is_queue_up,
                    is_refund: val.is_refund,
                    publish_time: val.publish_time,
                    tip: val.tip,
                })

            }

            if (!is_save) {
                return false
            }

            // 判断是否有未排课时间，需要提醒
            if (!this.Tool.is_empty(warning_tip)) {
                console.log('排课警告', warning_tip)
                let message = ''
                for (let val_tip of warning_tip) {
                    message += `周${val_tip.week}（${val_tip.date}）未排课</br>`
                }
                message += ' <strong>是否继续?<strong>'
                this.$confirm(message, '提示', {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    console.log('arrange_data', arrange_data)
                    this.is_save(arrange_data, index)
                }).catch(() => {
                });
            } else {
                console.log('arrange_data', arrange_data)
                this.is_save(arrange_data, index)
            }
        },
        // 提交保存
        is_save(arrange_data = [], index) {
            // 无排课信息
            if (this.Tool.is_empty(arrange_data)) {
                this.Tool.errormes({
                    "code": 10001,
                    "message": "未排课",
                })
                return false
            }

            // console.log('arrange_data', arrange_data)

            let postdata = {
                api_name: "lessonschedule.group.issave",
                token: this.Tool.get_l_cache('token'),
                arrange_data,
            }

            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getClassroomOccupyTimeData()   // 刷新课程占用时间
                            if (index === null) {
                                // this.createdTimeArrange()        // 清空所有数据
                            } else {
                                this.TimeArrange(index)      // 清空单条数据
                            }
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/store/store'})
            this.$router.go(-1)
        },
        // 获取可选门店
        getStore() {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getstore",
                token: this.Tool.get_l_cache('token'),
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.store_optionals = json.data.list
                } else {
                    this.store_optionals = []
                    this.Tool.errormes(json)
                }

            })
        },
        // 获取可选教室
        getClassroom(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getclassroom",
                token: this.Tool.get_l_cache('token'),
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false

                this.classroom_uuid = null            // 清空教室数据
                this.select_classroom_data = null            // 清空教室数据
                this.selectWeek = ''                // 清空选择周信息
                this.weekArrangeData = []           // 清空一周安排数据

                if (json.code === 0) {
                    this.classroom_optionals = json.data.list
                } else {
                    this.classroom_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取可选教练
        getcoach(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getcoach",
                token: this.Tool.get_l_cache('token'),
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                this.select_coach = ''
                if (json.code === 0) {
                    this.coach_optionals = json.data.list
                } else {
                    this.coach_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取可选课程
        getlesson(store_uuid) {
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getlesson",
                token: this.Tool.get_l_cache('token'),
                lesson_type: 1,
                store_uuid
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.lesson_optionals = json.data.list
                } else {
                    this.lesson_optionals = []
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取教室使用数据
        getClassroomOccupyTimeData() {
            console.log('获取教室占用数据')
            this.classroom_occupy = []      // 清空原有数据
            let postdata = {
                api_name: "lessonschedule.lessonarrange.getclassroomoccupy",
                token: this.Tool.get_l_cache('token'),
                classroom_uuid: this.classroom_uuid,
                datearea: this.getDateArea(this.selectWeek)
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    console.log('json.data', json.data)
                    this.classroom_occupy = json.data
                } else {
                    if (json.code !== 10002) {
                        this.Tool.errormes(json)
                    }
                }
            })
        },
        // 获取教练时间占用数据
        getCoachOccupyTimeData() {

        },
        // 创建排课时间表
        createdTimeArrange() {
            console.log('创建排课时间表')
            let selectWeek = this.selectWeek        // 选择的周数据

            // 创建排期时间表
            let DateArea = this.getDateArea(selectWeek)
            // console.log('DateArea', DateArea)
            this.weekArrangeData = []
            for (let val of DateArea) {
                this.weekArrangeData.push(this.initTimeArrangeData(val.week, val.date))
            }
            // this.weekArrangeData = DateArea
        },
        // 初始化排表信息
        initTimeArrangeData(week = 'x', date = 'x') {
            return {
                week,
                date,
                title: '',
                quota: 0,
                bookings_min: 0,
                determine_time: 2,
                originally_price: 0,
                price: 0,
                start_time: '',
                end_time: '暂无',
                publish_time: '',
                is_queue_up: 1,
                is_refund: 1,
                tip: '',
            }
        },
        // 获取选中的一周日期数据
        getDateArea(currentTime) {
            let currentDate = new Date(currentTime)
            let timesStamp = currentDate.getTime()
            let currenDay = currentDate.getDay()
            let dates = []
            for (let i = 0; i < 7; i++) {
                // let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toLocaleDateString().replace(/\//g, '-')
                let date = new Date(timesStamp + 24 * 60 * 60 * 1000 * (i - (currenDay + 6) % 7)).toISOString().split('T')[0]
                let week = '无'
                if (i === 0) week = '一'
                if (i === 1) week = '二'
                if (i === 2) week = '三'
                if (i === 3) week = '四'
                if (i === 4) week = '五'
                if (i === 5) week = '六'
                if (i === 6) week = '日'
                dates.push({
                    week,
                    date,
                });
            }
            return dates
        },
        // 选择门店
        selectStore(store_uuid) {
            this.getClassroom(store_uuid)   // 获取教室数据
            this.getcoach(store_uuid)       // 获取教练数据
            this.getlesson(store_uuid)      // 获取课程数据

            for (let item of this.store_optionals) {
                if (item.store_uuid === store_uuid) {
                    this.select_store_data = item
                    break
                }
            }
        },
        // 选择教室
        selectClassroom(classroom_uuid) {
            for (let item of this.classroom_optionals) {
                if (item.classroom_uuid === classroom_uuid) {
                    this.select_classroom_data = item
                    break
                }
            }
            this.selectWeek = ''
        },
        // 选择教练
        selectCoach(coach_uuid, index) {
            console.log('coach_uuid', coach_uuid)
            // 判断是否同步选择教练
            if (this.arrangeQuickTool.indexOf('sync_select_coach') >= 0 && index === 0) {
                for (let item of this.weekArrangeData) {
                    item.coach_uuid = coach_uuid
                }
            }
        },
        // 选择日期
        selectDate(selectWeek) {
            console.log('selectWeek', selectWeek)
            if (!this.Tool.is_empty(selectWeek)) {
                this.createdTimeArrange()
                this.getClassroomOccupyTimeData()
            } else {
                this.weekArrangeData = []
            }
        },
        // 选择课程
        selectLesson(lesson_uuid, index) {
            console.log('lesson_uuid', lesson_uuid)
            let lesson_data;
            for (let item of this.lesson_optionals) {
                if (item.lesson_uuid === lesson_uuid) {
                    lesson_data = item
                    break
                }
            }

            // 判断是否同步选择教练
            if (this.arrangeQuickTool.indexOf('sync_select_lesson') >= 0 && index === 0) {
                for (let item of this.weekArrangeData) {
                    item.lesson_uuid = lesson_data.lesson_uuid
                    item.store_lesson_uuid = lesson_data.store_lesson_uuid
                    item.lesson_name = lesson_data.lesson_name
                    item.title = lesson_data.lesson_name
                    item.lesson_duration = lesson_data.lesson_duration
                    item.price_indication = lesson_data.price_indication
                    item.price_store = lesson_data.price_store
                    item.price = lesson_data.price_store
                    item.lesson_quota = lesson_data.quota
                    item.quota = lesson_data.quota
                    item.end_time = this.computeEndTime(item.start_time, item.lesson_duration)
                }
            } else {
                this.weekArrangeData[index].store_lesson_uuid = lesson_data.store_lesson_uuid
                this.weekArrangeData[index].lesson_name = lesson_data.lesson_name
                this.weekArrangeData[index].title = lesson_data.lesson_name
                this.weekArrangeData[index].lesson_duration = lesson_data.lesson_duration
                this.weekArrangeData[index].price_indication = lesson_data.price_indication
                this.weekArrangeData[index].price_store = lesson_data.price_store
                this.weekArrangeData[index].price = lesson_data.price_store
                this.weekArrangeData[index].lesson_quota = lesson_data.quota
                this.weekArrangeData[index].quota = lesson_data.quota

                this.weekArrangeData[index].end_time = this.computeEndTime(this.weekArrangeData[index].start_time, lesson_data.lesson_duration)
            }
        },
        // 选择上架时间
        selectPublishTime(publish_time, index) {
            console.log('publish_time', publish_time)
            // 判断是否同步选择发布时间
            if (this.arrangeQuickTool.indexOf('sync_select_publish_time') >= 0 && index === 0) {
                for (let item of this.weekArrangeData) {
                    item.publish_time = publish_time
                }
            }
        },
        // 选择开始时间
        selectStartTime(start_time, lesson_duration, index) {
            console.log('start_time', start_time)
            if (this.arrangeQuickTool.indexOf('sync_select_start_time') >= 0 && index === 0) {
                for (let item of this.weekArrangeData) {
                    item.start_time = start_time
                    item.end_time = this.computeEndTime(start_time, item.lesson_duration)
                }
            } else {
                this.weekArrangeData[index].end_time = this.computeEndTime(start_time, lesson_duration)
            }
        },
        // 计算结束时间
        computeEndTime(start_time, lesson_duration) {
            let end_time = '暂无'
            // 判断是否选择了 开始时间和门店课程，只要有一个没选择 则不计算结束时间
            if (this.Tool.is_empty(start_time) || this.Tool.is_empty(lesson_duration)) {
                return end_time
            }
            // 开始时间转换成秒+课程时长
            let second = 0      // 秒数
            let start_time_data = start_time.split(":")     // 时间数据
            second = parseInt(start_time_data[0]) * 60 + parseInt(start_time_data[1]) + lesson_duration

            // 计算结束时间hh:mm
            let end_time_h = parseInt(second / 60)
            let end_time_m = parseInt(second % 60)
            // 转成字符串
            end_time_h = end_time_h < 10 ? '0' + end_time_h : end_time_h + ''
            end_time_m = end_time_m < 10 ? '0' + end_time_m : end_time_m + ''
            end_time = end_time_h + ':' + end_time_m

            return end_time
        },
        // 清空
        TimeArrange(index) {
            console.log('清空', index)
            this.weekArrangeData[index].coach_uuid = ''
            this.weekArrangeData[index].lesson_uuid = ''
            this.weekArrangeData[index].title = ''
            this.weekArrangeData[index].lesson_quota = ''
            this.weekArrangeData[index].quota = 0
            this.weekArrangeData[index].bookings_min = 0
            this.weekArrangeData[index].determine_time = 2
            this.weekArrangeData[index].price_indication = ''
            this.weekArrangeData[index].price_store = ''
            this.weekArrangeData[index].price = 0
            this.weekArrangeData[index].originally_price = 0
            this.weekArrangeData[index].start_time = ''
            this.weekArrangeData[index].lesson_duration = ''
            this.weekArrangeData[index].end_time = '暂无'
            this.weekArrangeData[index].publish_time = ''
            this.weekArrangeData[index].is_queue_up = 1
            this.weekArrangeData[index].is_refund = 1
            this.weekArrangeData[index].tip = ''
        },
        // 判断是否有门店教室占用信息
        classroomOccupy(date, is_show = 0) {
            // console.log('is_show', is_show)
            let datearray = date.split("-")
            let y = datearray[0]
            let m = datearray[1]
            let d = datearray[2]
            let date_ymd = parseInt(y + m + d)
            let classroom_occupy = this.classroom_occupy[date_ymd]
            if (this.Tool.is_empty(classroom_occupy)) {
                if (is_show) {
                    return false
                } else {
                    return []
                }
            } else {
                if (is_show) {
                    return true
                } else {
                    return classroom_occupy
                }
            }
        },
        // 占用提示样式
        occupyTipStyle(item_data) {
            // console.log('占用提示样式', item_data)
            // 开始时间高度
            let start_time_hi = item_data.start_time_hi
            let start_time_hi_str = start_time_hi + ''

            let start_time_h = parseInt(start_time_hi / 100)
            let start_time_i = parseInt(start_time_hi_str.slice(-2))
            let start_top = start_time_h * 60 + start_time_i

            // 结束时间高度
            let end_time_hi = item_data.end_time_hi
            let end_time_hi_str = end_time_hi + ''

            let end_time_h = parseInt(end_time_hi / 100)
            let end_time_i = parseInt(end_time_hi_str.slice(-2))
            let end_top = end_time_h * 60 + end_time_i
            end_top = end_top > 1440 ? 1440 : end_top   // 最高1440

            // tip高度
            let height = end_top - start_top
            // console.log('height', height)

            return `top:${start_top}px;height: ${height}px;`
            // return `top:10px;height: 50px;`
        },
        // 跳转详情
        to_details(lesson_type, good_uuid) {
            if (lesson_type === 1) {
                this.$router.push({path: '/lessonschedule/groupteaching/edit', query: {good_uuid: good_uuid}})
            } else if (lesson_type === 2) {
                this.$router.push({path: '/lessonschedule/camp/edit', query: {good_uuid: good_uuid}})
            }
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*时间表*/
.time-arrange {
    /*border: #3a8ee6 1px solid;*/
    display: flex;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 1px 1px 5px #d9d9d9;
    border-radius: 5px;
}

.font-size-mini {
    font-size: 12px;
}

/*课程表天*/
.timetable .time-arrange-column {
    width: 100% !important;
    position: relative !important;
}

.timetable .time-tip {
    width: 100%;
    position: absolute;
}

.timetable .time-h {
    height: 60px;
}

.timetable .time-h-border {
    border-top: #EBEEF5 1px solid;
}

.timetable .time-arrange-column-store-classroom {
    width: calc(100% - 45px);
    margin-left: 40px;
    position: absolute;
}


.time-arrange-list {
    /*margin-right: 20px;*/
    /*border: 1px solid #888888;*/
    /*border-radius: 5px;*/
    /*box-shadow: 1px 1px 5px #cccccc;*/
    padding: 0 5px;
}

/*小号字体*/
.text-se {
    font-size: 12px;
}

.time-arrange-column {
    display: flex;
    /*min-height: 960px;*/
    height: 1440px;
    width: 30px;
    border: 1px solid #EBEEF5;
    margin-right: 5px;
    font-size: 12px;
    /*margin-left: 5px;*/
}

.time-arrange-column-type {
    position: relative;
    width: 100%;
}

/*门店时间占用*/
.time-arrange-column-store-classroom-card {
    border-radius: 5px;
    width: 100%;
    background: #ffa940;
    /*position: relative;*/
    position: absolute;
    /*溢出滚动条*/
    overflow-x: auto;
    /*溢出隐藏*/
    /*overflow: hidden;*/
}

.time-arrange-column-store-classroom-card-button {
    padding: 0px;
    font-size: 12px;
}

/*隐藏滚动条*/
.time-arrange-column-store-classroom-card::-webkit-scrollbar {
    width: 0 !important
}

/*教练时间占用*/
.time-arrange-column-coach {
    border-radius: 5px;
    width: 100%;
    height: 50px;
    background: #40a9ff;
}

.time-arrange-form {
    /*margin-right: 10px;*/
    /*background: #5daf34;*/
    width: calc(100% - 30px);
}

.time-arrange-form span {
    display: block;
    width: 100%;
    background-color: #f5f7fa;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    font-size: 12px;
    padding: 0 15px;
    box-sizing: border-box;
    /*text-align: center;*/
}

>>> .time-arrange .time-arrange-list .el-form-item__label {
    padding-bottom: 0px !important;

}

</style>
